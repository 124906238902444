import styled from "styled-components";
import { rem } from "../../utils/style";

// Store
import { useSelector } from "react-redux";
import promotionSelectors from "../../store/promotion/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";

// Utils
import { getProductAttributes } from "../../utils/planToProduct";
import metrics from "../../utils/metrics";

// Components
import QuickAddCardWide from "../QuickAddCardWide";
import ScrollableOverflow from "../ScrollableOverflow";
import Text from "../Text";
import planSelectors from "../../store/plan/selectors";

const UpsellContainer = styled.div`
  justify-self: flex-end;
`;

const UpsellHeader = styled.div`
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  margin-bottom: var(--spacing-0_5);
`;

const QuickAddCardWrapper = styled.div`
  margin: 0 8px 0 0;
  flex: 1 0 calc(33.333% - (32px / 3));

  &:last-of-type {
    margin-right: 0;
  }
`;

const UpsellContentWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const UpsellContent = styled.div`
  width: 100%;
  display: flex;
`;

export default function FlyoutCartUpsell({
  isProcessing,
  upsellSkus,
  contentfulProducts,
  intervalCount = 30,
}) {
  let inStockSkus = getInStockSkus(upsellSkus);

  const hasProductOfferInCart = useSelector(
    cartProductSelectors.activeCartProducts,
  ).some((cartProduct) => cartProduct.productOfferId);

  if (!inStockSkus || !inStockSkus.length) return null;

  function contentfulProductStockStatus(sku) {
    const product = contentfulProducts.filter((product) => product.sku === sku);
    if (product.length > 0) return product[0].stockStatus;

    return null;
  }

  function getInStockSkus(skus) {
    const skuList = skus;

    let inStockSkus = skuList.reduce((result, sku) => {
      const productStockStatus = contentfulProductStockStatus(sku);
      if (productStockStatus === "in stock") {
        result.push(sku);
      }
      return result;
    }, []);

    return inStockSkus;
  }

  return (
    <UpsellContainer>
      <UpsellHeader>
        <Text
          id="cart.flyout.upsell-heading-1"
          defaultMessage="You Might Also Like"
        />
      </UpsellHeader>
      <UpsellContentWrapper className="d-flex">
        <ScrollableOverflow centered={false} bleedLeft={true} bleedRight={true}>
          <UpsellContent>
            {inStockSkus.map((sku) => (
              <UpsellCard
                key={sku}
                sku={sku}
                isProcessing={isProcessing}
                hasProductOfferInCart={hasProductOfferInCart}
                intervalCount={intervalCount}
              />
            ))}
          </UpsellContent>
        </ScrollableOverflow>
      </UpsellContentWrapper>
    </UpsellContainer>
  );
}

function UpsellCard({
  sku,
  intervalCount = 30,
  isProcessing,
  hasProductOfferInCart,
}) {
  let promotion = useSelector(promotionSelectors.appliedPromotion);
  const plans = useSelector((state) =>
    planSelectors.allPlansForProductSku(state, {
      sku,
    }),
  );

  if (!plans) return null;

  const plan = plans.find((plan) => {
    if (intervalCount === 0) {
      return plan.billingType === "one_time";
    }
    return plan.intervalCount === intervalCount;
  });

  if (!plan) return null;

  const { id: planId } = plan;

  if (!planId) return null;

  const attributes = getProductAttributes(planId);
  const productName = attributes.name;

  function handleCTAClick() {
    metrics.track("CTA Clicked", {
      location: "Cart Flyout Upsell",
      title: productName,
    });
  }

  if (hasProductOfferInCart) {
    promotion = null;
  }

  return (
    <QuickAddCardWrapper key={planId}>
      <QuickAddCardWide
        planId={planId}
        disabled={isProcessing}
        onClick={handleCTAClick}
        promotion={promotion}
        blueButton={false}
        isLink={false}
        location="Flyout Cart Upsell"
      />
    </QuickAddCardWrapper>
  );
}
