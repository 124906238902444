import styled from "styled-components";
import { Icons } from "../../utils/react-svg";

const QuantityStepperWrapper = styled.div`
  padding: var(--spacing-0_75);
  border: 1px solid var(--indigo-blue-20);
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-0_5);
  min-width: 40px;
  height: 40px;

  &.round {
    width: 40px;
  }

  transition: border-color 0.3s ease;

  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    svg {
      width: 16px;
      height: 16px;
    }

    &::after {
      content: "";
      display: block;
      width: 36px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
    }
  }

  &:hover {
    border-color: var(--indigo-blue);
  }
`;

interface QuantityStepperProps {
  quantity: number;
  min?: number;
  disableAdd: boolean;
  removeAction: () => void;
  increaseAction: () => void;
  decreaseAction: () => void;
  quantityOnly?: boolean;
  removeOnly?: boolean;
  productName?: string;
}

const QuantityStepper = ({
  quantity,
  min = 1,
  disableAdd,
  removeAction,
  increaseAction,
  decreaseAction,
  quantityOnly,
  removeOnly,
  productName,
}: QuantityStepperProps) => {
  const decreaseDisabled = quantity <= min;

  if (removeOnly) {
    return (
      <QuantityStepperWrapper className="round">
        <button
          className="typography-label3"
          onClick={removeAction}
          aria-label={`Remove ${productName} from cart`}
        >
          <Icons.Trash />
        </button>
      </QuantityStepperWrapper>
    );
  }

  if (quantityOnly) {
    return (
      <QuantityStepperWrapper className="round">
        <span
          className="typography-label3"
          style={{
            minWidth: "9px",
            textAlign: "center",
          }}
        >
          {quantity}
        </span>
      </QuantityStepperWrapper>
    );
  }

  return (
    <QuantityStepperWrapper>
      {decreaseDisabled ? (
        <button
          className="typography-label3"
          onClick={removeAction}
          aria-label={`Remove ${productName} from cart`}
        >
          <Icons.Trash />
        </button>
      ) : (
        <button
          className="typography-label3"
          onClick={decreaseAction}
          aria-label={`Decrease ${productName} quantity from ${quantity} to ${
            quantity - 1
          }`}
          disabled={decreaseDisabled}
        >
          <Icons.MinusBase16x16 />
        </button>
      )}
      <span
        className="typography-label3"
        style={{
          minWidth: "9px",
          textAlign: "center",
        }}
      >
        {quantity}
      </span>
      <button
        className="typography-label3"
        onClick={increaseAction}
        aria-label={`Increase ${productName} quantity from ${quantity} to ${
          quantity + 1
        }`}
        disabled={disableAdd}
      >
        <Icons.PlusBase16x16 />
      </button>
    </QuantityStepperWrapper>
  );
};

export default QuantityStepper;
