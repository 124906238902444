import styled from "styled-components";

// Components
import Text from "../Text";
import StripeExpressCheckout from "./StripeExpressCheckout";

// Hooks
import useVariation from "../../hooks/useVariation";

// Utils
import { getCheckoutUrlWithoutPlan } from "../../utils/urlBuilder";
import { trackCheckoutCTAClicked } from "../../utils/tracking/cart";
import { Icons } from "../../utils/react-svg";
import { navigate } from "../../services/navigation";

const BottomCheckoutButtonContainer = styled.div`
  width: 100%;
  border-top: 1px solid var(--cool-40);
  padding: var(--spacing-1) var(--spacing-1_5)
    calc(var(--spacing-1) + env(safe-area-inset-bottom));

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--spacing-0_5);
`;

const BottomReactCheckoutButton = styled.button`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 25px;
  border: none;
  background: var(--indigo-blue);
  color: var(--white);
  height: 48px;
  transition: background 0.2s, color 0.2s;
  font-size: 16px;
  line-height: 24px;

  // Set min-width to share space with Apple Pay when present
  min-width: calc(50% - var(--spacing-0_25));
  width: 100%;

  svg {
    path, rect {
      transition: stroke 0.2s;
    }
  }

  &:hover {
    background: var(--yellow-ochre);
    color: var(--indigo-blue);

    svg {
      path, rect {
        stroke: var(--indigo-blue);
      }
    }
  }
  }
`;

const ButtonCopy = styled.span.attrs({ className: "typography-label1" })`
  font-size: var(--spacing-1, 16px) !important;
`

export default function FlyoutCartBottom({
  activeCart,
  isAddingToSubscription,
  isProcessing,
  cartProducts,
  cartQuantity,
  activeCoupon,
}) {
  const checkoutFeatureFlag = useVariation("react-checkout-enabled");
  const checkoutUrl = getCheckoutUrlWithoutPlan();
  const canCheckout = !!(!isProcessing && cartQuantity);
  const newCheckoutAvailable = checkoutFeatureFlag;

  const expressCheckoutEligible =
    canCheckout && !isAddingToSubscription && activeCart.total > 0;

  async function handleCheckoutCTAClicked() {
    await trackCheckoutCTAClicked();

    if (newCheckoutAvailable) {
      navigate("/checkout");
    } else {
      window.location.href = checkoutUrl;
    }
  }

  return (
    <BottomCheckoutButtonContainer>
      {expressCheckoutEligible && (
        <StripeExpressCheckout
          cart={activeCart}
          cartProducts={cartProducts}
          coupon={activeCoupon}
        />
      )}
      <BottomReactCheckoutButton
        disabled={!canCheckout}
        className="fullwidth"
        onClick={handleCheckoutCTAClicked}
        aria-label="Checkout"
        aria-disabled={!canCheckout}
      >
        <Icons.CreditCard />
        <ButtonCopy>
          <Text id="cart.flyout.button-checkout" defaultMessage="Checkout" />
        </ButtonCopy>
      </BottomReactCheckoutButton>
    </BottomCheckoutButtonContainer>
  );
}
