import { Component } from "react";
import styled from "styled-components";
import PulseIcon from "../animation/PulseIcon";
import { Icons } from "../../utils/react-svg";

const CartBannerWrapper = styled.div`
  padding: var(--spacing-0_75) var(--spacing-1_5);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-0_75);
  background-color: var(--yellow-ochre-20);

  &.neutral-banner {
    background-color: var(--dark-sky-blue-20);
    align-items: flex-start;
  }

  .icon-container {
    width: 18px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 18px;

    svg {
      opacity: 0.4;
    }
  }
`;

export default class CartBanner extends Component {
  render() {
    const { bannerMessage, className = "" } = this.props;

    const Icon = className.includes("neutral-banner") ? Icons.Info : PulseIcon;

    return (
      <CartBannerWrapper className={className}>
        <span className="icon-container">
          <Icon />
        </span>
        <span className="typography-body3 font-circular">{bannerMessage}</span>
      </CartBannerWrapper>
    );
  }
}
