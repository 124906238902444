import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { sha256 } from "js-sha256";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { navigate } from "../../services/navigation";
import cartProductSelectors from "../../store/cart-product/selectors";
import { getStore } from "../../store/createStore";
import i18nSelectors from "../../store/i18n/selectors";
import { productOfferForId } from "../../store/product-offer/selectors";
import fetchInternal from "../../utils/fetch";
import metrics from "../../utils/metrics";
import {
  getProductForSku,
  getProductSkuForPlanId,
} from "../../utils/planToProduct";
import { trackCheckoutStarted } from "../../utils/tracking/cart";
import {
  stripeElementsConfig,
  tryApiPurchase,
  tryCreateConfirmationToken,
  tryHandleNextAction,
} from "../checkout/utils";

const ExpressCheckoutContainer = styled.div`
  padding: 0;
`;

const StripeExpressCheckout = ({ cart, cartProducts, coupon }) => {
  const stripe = useStripe();
  const elements = useElements();
  const store = getStore();

  const activeCartProducts = useSelector(
    cartProductSelectors.sortedActiveCartProducts,
  );
  const hasMultipleCadences =
    Object.keys(cart.recurringBillingDetails).length > 1;
  const [recurringBillingCadence, recurringBillingDetails] =
    Object.entries(cart.recurringBillingDetails)[0] || [];

  const [, /*errorMessage*/ setErrorMessage] = useState();
  const [display, setDisplay] = useState("none");

  const expressCheckoutOptions = {
    buttonHeight: 48,
    paymentMethodOrder: ["apple_pay"],
    paymentMethods: {
      applePay: "auto",
    },
  };

  const updateElements = () => {
    const elementsConfig = stripeElementsConfig(
      cart,
      !isEmpty(recurringBillingDetails),
    );
    elements.update({
      ...elementsConfig,
      appearance: {
        variables: {
          borderRadius: "25px",
        },
      },
    });
  };

  if (elements && cart && isEmpty(cartProducts) === false) {
    updateElements();
  }

  const onReady = ({ availablePaymentMethods }) => {
    if (!availablePaymentMethods.applePay) return;

    setDisplay("block");
  };

  const onClick = ({ resolve }) => {
    metrics.track("CTA Clicked", {
      title: "Express Checkout Payment Option",
      location: "Cart",
    });

    trackCheckoutStarted();

    let lineItems = cartProducts.map((cartProduct) => {
      const { quantity, planId, productOfferId, productPrice } = cartProduct;
      let name = "";

      if (productOfferId) {
        const productOffer = productOfferForId(
          store.getState(),
          productOfferId,
        );
        name = productOffer.name;
      } else {
        const productSku = getProductSkuForPlanId(planId);
        const product = getProductForSku(productSku);
        name = product.name;
      }

      return {
        name: `${quantity}x ${name}`,
        amount: productPrice * quantity,
      };
    });

    if (cart.discountAmount > 0) {
      lineItems.push({
        name: "Discount",
        amount: -cart.discountAmount,
      });
    }

    lineItems.push({
      name: "Tax",
      amount: cart.totalTax || 0,
    });

    // if cart.shippingAmount is not null, add shipping line item
    if (cart.shippingAmount > 0) {
      lineItems.push({
        name: "Shipping",
        amount: cart.shippingAmount,
      });
    }

    let activeStore = i18nSelectors.activeStore(store.getState());

    // TODO: Replace with cart shipping estimate.
    const deliveryEstimate = {
      maximum: { unit: "day", value: 6 },
      minimum: { unit: "day", value: 4 },
    };
    const shippingRate = cart.shippingAmount
      ? { id: "shipping", displayName: "Shipping", amount: cart.shippingAmount }
      : { id: "free-shipping", displayName: "Free shipping", amount: 0 };
    const options = {
      emailRequired: true,
      phoneNumberRequired: false,
      shippingAddressRequired: true,
      allowedShippingCountries: activeStore.countries,
      lineItems: lineItems,
      shippingRates: [{ ...shippingRate, deliveryEstimate }],
    };

    if (recurringBillingDetails) {
      options.applePay = {
        recurringPaymentRequest: {
          paymentDescription: "My Ritual Subscription",
          managementURL: "https://account.ritual.com/",
          regularBilling: {
            amount: recurringBillingDetails.amount,
            label: "Recurring subscription (plus tax)",
            recurringPaymentIntervalUnit: "day",
            recurringPaymentIntervalCount: Number(recurringBillingCadence),
            recurringPaymentStartDate: new Date(
              new Date().setDate(
                new Date().getDate() + recurringBillingCadence,
              ),
            ),
          },
          billingAgreement: `Some of the items you are purchasing will automatically renew as a subscription and will be billed every ${recurringBillingCadence} days until you cancel. You can cancel anytime by visiting your account page on our website. By clicking Pay, you confirm that you have read, understand, and agree to the Terms of Service and Privacy Policy.`,
        },
      };
    }

    sessionStorage.setItem("rit-purchased_cart", JSON.stringify(cart));
    sessionStorage.setItem(
      "rit-purchased_cart_products",
      JSON.stringify(cartProducts),
    );

    resolve(options);
  };

  const onCancel = () => {
    setErrorMessage(null);
    sessionStorage.removeItem("rit-cart_id");
    sessionStorage.removeItem("rit-payment_intent_client_secret");
    sessionStorage.removeItem("rit-order_number");
    sessionStorage.removeItem("rit-reset_password_token");
    sessionStorage.removeItem("rit-subscription_id");
    sessionStorage.removeItem("rit-express_checkout_confirm_event");
    sessionStorage.removeItem("rit-purchased_cart");
    sessionStorage.removeItem("rit-purchased_cart_products");

    metrics.track("Payment Info Cancelled", {
      payment_element: "Express Checkout",
    });
  };

  const onShippingAddressChange = async ({ resolve, address }) => {
    let payload = {
      shipping_address: {
        city: address.city,
        state: address.state,
        postal_code: address.postal_code,
        country: address.country,
      },
    };
    if (coupon) {
      payload.discount_code = coupon.code;
    }

    const response = await fetchInternal(`carts/${cart.id}`, {
      method: "PATCH",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      fetchOptions: {
        addCastleRequestToken: true,
      },
      body: JSON.stringify(payload),
    });

    let updatedLineItems = cartProducts.map((cartProduct) => {
      const { quantity, planId, productOfferId, productPrice } = cartProduct;
      let name = "";

      if (productOfferId) {
        const productOffer = productOfferForId(
          store.getState(),
          productOfferId,
        );
        name = productOffer.name;
      } else {
        const productSku = getProductSkuForPlanId(planId);
        const product = getProductForSku(productSku);
        name = product.name;
      }

      return {
        name: `${quantity}x ${name}`,
        amount: productPrice * quantity,
      };
    });

    if (response.data.attributes.discount_amount > 0) {
      updatedLineItems.push({
        name: "Discount",
        amount: -response.data.attributes.discount_amount,
      });
    }

    if (response.data.attributes.tax_inclusive === false) {
      updatedLineItems.push({
        name: "Tax",
        amount: response.data.attributes.total_tax,
      });
    }

    if (response.data.attributes.shipping_amount > 0) {
      updatedLineItems.push({
        name: "Shipping",
        amount: response.data.attributes.shipping_amount,
      });
    }

    sessionStorage.setItem(
      "rit-purchased_cart",
      JSON.stringify(response.data.attributes),
    );

    elements.update({
      amount: response.data.attributes.total,
      currency: response.data.attributes.currency,
    });

    resolve({
      lineItems: updatedLineItems,
    });
  };

  const onConfirm = async (event) => {
    if (!stripe) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    metrics.track("Payment Info Entered", {
      payment_method: event.expressPaymentType,
      payment_element: "Express Checkout",
    });

    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.log("submitError", submitError);
      setErrorMessage(submitError.message);
      return;
    }

    // Store the event and cart in session storage so we can access it on the confirmation page.
    sessionStorage.setItem(
      "rit-express_checkout_confirm_event",
      JSON.stringify(event),
    );

    const { error: confirmationTokenError, confirmationToken } =
      await tryCreateConfirmationToken(stripe, elements);
    if (confirmationTokenError) {
      console.log("confirmationTokenError", confirmationTokenError);
      setErrorMessage(confirmationTokenError.message);
      return;
    }

    sessionStorage.setItem("rit-cart_id", cart.id);
    sessionStorage.setItem(
      "rit-purchased_cart_products",
      JSON.stringify(activeCartProducts),
    );

    const { response: purchase, error } = await tryApiPurchase({
      confirmationToken,
      cartId: cart.id,
    });
    if (error) {
      console.log("purchaseError", error);
      setErrorMessage(error.message);
      return;
    }

    metrics.identify(purchase.user_id, {
      first_name: purchase.first_name,
      last_name: purchase.last_name,
      email: event.billingDetails.email,
    });
    sessionStorage.setItem("rit-checkout", "home");
    sessionStorage.setItem("rit-order_number", purchase.order_number);

    if (purchase.reset_password_token) {
      sessionStorage.setItem(
        "rit-reset_password_token",
        purchase.reset_password_token,
      );

      metrics.track("User Signup", {
        method: "provider:ritual",
        userHashedEmail: sha256(event.billingDetails.email),
      });
    }

    if (purchase.requires_action && purchase.client_secret) {
      const { error: handleNextActionError } = await tryHandleNextAction(
        purchase.client_secret,
        stripe,
      );
      if (handleNextActionError) {
        console.log("handleNextActionError", handleNextActionError);
        setErrorMessage(handleNextActionError.message);
        return;
      }
    }

    navigate("/checkout/confirmation");
  };

  if (hasMultipleCadences) return null;

  return (
    <div style={{ display }}>
      <ExpressCheckoutContainer id="express-checkout-element">
        <ExpressCheckoutElement
          options={expressCheckoutOptions}
          onReady={onReady}
          onConfirm={onConfirm}
          onClick={onClick}
          onShippingAddressChange={onShippingAddressChange}
          onCancel={onCancel}
        />
        {/* {errorMessage && <div>{errorMessage}</div>} */}
      </ExpressCheckoutContainer>
    </div>
  );
};

export default StripeExpressCheckout;
