import { memo } from "react";
import styled from "styled-components";

// Components
import MagicModal, { ModalStyle } from "../MagicModal";
import MagicFade, { FadeStyles } from "../MagicFade";
import FlyoutUpsell from "./FlyoutCartUpsell";
import FlyoutCartBottom from "./FlyoutCartBottom";
import FlyoutCartItemList from "./FlyoutCartItemList";
import CartTopBannerArea from "./CartTopBannerArea";
import Text from "../Text";

// Redux
import { useSelector } from "react-redux";
import cartSelectors from "../../store/cart/selectors";
import couponSelectors from "../../store/coupon/selectors";
import promotionSelectors from "../../store/promotion/selectors";
import cartProductSelectors, {
  hasProductOfferInCart,
} from "../../store/cart-product/selectors";
import subscriptionSelectors from "../../store/subscription/selectors";
import staticSelectors from "../../store/staticData/selectors";

// Services
import cartService from "../../services/cart";

// Utils
import { Icons } from "../../utils/react-svg";
import { getBundleCartLimit } from "../../utils/bundle";
import LedgerCouponGiftCards from "../checkout/LedgerCouponGiftCards";
import LedgerReceipt from "../checkout/LedgerReceipt";

const CartComponentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
`;

const ModalContents = styled.section`
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .flyout-cart-banners {
    width: 100%;

    & > * {
      margin: 0;
    }
  }
`;

const ModalContentsInner = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  padding: var(--spacing-1, 16px) var(--spacing-1_5, 24px)
    var(--spacing-1_5, 24px);

  display: flex;
  flex-direction: column;

  overflow-x: hidden;

  ${FadeStyles}

  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    width: 6rem;
    margin-top: 60px;
    margin-bottom: 15px;
  }
`;

const ModalTitle = styled.h1`
  display: flex;
  justify-content: space-between;
  margin: 0;

  button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      display: block;
      width: 44px;
      height: 44px;
      position: absolute;
    }

    svg {
      width: 16px;
      height: 16px;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      svg {
        opacity: 0.6;
      }
    }
  }
`;

const CartItemList = memo(FlyoutCartItemList);
const Upsell = memo(FlyoutUpsell);
const BannerArea = memo(CartTopBannerArea);

function LoadingComponent() {
  return (
    <Loading>
      <Icons.Loading />
      <Text id="cart.flyout.loading" defaultMessage="Loading..." />
    </Loading>
  );
}

export default function FlyoutCart(props) {
  const isOpen = useSelector(cartSelectors.showFlyoutCart);
  const activeCart = useSelector(cartSelectors.activeCart);
  const activeCoupon = useSelector(couponSelectors.activeCoupon);
  const appliedPromotion = useSelector(promotionSelectors.appliedPromotion);
  const hasActiveSubscription = useSelector(
    subscriptionSelectors.hasActiveSubscription,
  );
  const activeCartProducts = useSelector(
    cartProductSelectors.sortedActiveCartProducts,
  );
  const activeCartProductCadences = useSelector((state) =>
    activeCartProducts.reduce((cadences, product) => {
      const plan = product.plan(state);
      return cadences.add(plan?.intervalCount || 30);
    }, new Set()),
  );
  const hasMixedCadenceCart = activeCartProductCadences.size > 1;
  const cartQuantity = useSelector(
    cartProductSelectors.activeCartProductQuantity,
  );
  const contentfulProducts = useSelector(staticSelectors.contentfulProducts);
  const isProcessing = useSelector(cartSelectors.isProcessing);
  const productOfferInCart = useSelector(hasProductOfferInCart);

  const isAddingToSubscription =
    hasActiveSubscription && cartQuantity !== getBundleCartLimit();

  const upsellCartAmount = 2;
  // Should only show upsell if you have up to 2 products in the cart,
  // but both have to be either one-time or 30 day cadence
  const shouldShowUpsell =
    activeCart &&
    cartQuantity <= upsellCartAmount &&
    cartQuantity > 0 &&
    !productOfferInCart && // Should not show for Product Offers
    !hasMixedCadenceCart && // Should not show for mixed cadence carts
    !activeCartProductCadences.has(90); // Should not show if any product has a 90 day cadence

  function onRequestClose(toggle) {
    if (!toggle) cartService.closeCart();
    if (props.onRequestClose) props.onRequestClose();
  }

  const shouldShowPromoCodeComponent =
    !isAddingToSubscription && !!cartQuantity && !productOfferInCart;
  const shouldShowLedger = !!cartQuantity;

  return (
    <MagicModal
      isOpen={!!isOpen}
      onRequestClose={onRequestClose}
      slideOut={true}
      contentStyle={ModalStyle}
      hideCloseButton={true}
    >
      <ModalContents data-body-scroll-lock-ignore={true}>
        {!!cartQuantity && (
          <div className="flyout-cart-banners">
            <BannerArea className={"compact"} />
          </div>
        )}
        <ModalContentsInner timeout={500} transitionKey={!!activeCart}>
          <ModalTitle className="typography-label1">
            <Text id="cart.flyout.heading" defaultMessage="Your Cart" />
            <button
              onClick={() => onRequestClose(false)}
              data-testid="Close cart"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") onRequestClose(false);
              }}
              aria-label="Close cart"
            >
              <Icons.Close24 />
            </button>
          </ModalTitle>
          <MagicFade transitionKey={!!activeCart}>
            {!activeCart ? (
              <LoadingComponent />
            ) : (
              <CartComponentsList>
                <CartItemList
                  data-body-scroll-lock-ignore={true}
                  cartProducts={activeCartProducts}
                  isProcessing={isProcessing}
                  cartQuantity={cartQuantity}
                  isFlyout={true}
                />
                {shouldShowUpsell && (
                  <Upsell
                    upsellSkus={activeCart.productRecommendations}
                    isProcessing={isProcessing}
                    contentfulProducts={contentfulProducts}
                    intervalCount={
                      activeCartProductCadences?.values().next().value || 30
                    }
                  />
                )}
                {shouldShowPromoCodeComponent && (
                  <LedgerCouponGiftCards
                    activeCart={activeCart}
                    disableGiftCards={true}
                  />
                )}
                {shouldShowLedger && (
                  <LedgerReceipt
                    activeCart={activeCart}
                    activeCartProducts={activeCartProducts}
                    activeCoupon={activeCoupon}
                    appliedPromotion={appliedPromotion}
                    isProcessing={isProcessing}
                    collapsed={false}
                    options={{
                      hideFutureCharges: true,
                      hideTax: true,
                      hideTotal: true,
                    }}
                  />
                )}
              </CartComponentsList>
            )}
          </MagicFade>
        </ModalContentsInner>
        <FlyoutCartBottom
          activeCart={activeCart}
          isAddingToSubscription={isAddingToSubscription}
          isProcessing={isProcessing}
          cartQuantity={cartQuantity}
          cartProducts={activeCartProducts}
          activeCoupon={activeCoupon}
          appliedPromotion={appliedPromotion}
        />
      </ModalContents>
    </MagicModal>
  );
}
