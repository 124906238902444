import { ReactNode, JSX, useEffect } from "react";
import Tippy from "@tippyjs/react";
import { Placement, Props as TippyProps } from "tippy.js";
import styled from "styled-components";
import "tippy.js/dist/tippy.css";

import { Icons } from "../utils/react-svg";

const StyledTooltip = styled(Tippy)<{ $width?: string }>`
  padding: var(--spacing-1) !important;
  background-color: var(--dark-sky-blue-20) !important;
  width: ${(props) => props.$width || "398px"} !important;
  color: var(--indigo-blue) !important;

  a {
    text-decoration: underline;
  }

  p {
    margin-bottom: 0;
  }

  .tippy-arrow {
    &::before {
      color: var(--dark-sky-blue-20) !important;
    }
  }
`;

const IconContainer = styled.span<{ $color: string; $hoverColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      stroke: ${(props) => props.$color} !important;

      &.icon-info-i-dot {
        stroke: none !important;
        fill: ${(props) => props.$color} !important;
      }
    }

    &:hover {
      path {
        stroke: ${(props) => props.$hoverColor} !important;

        &.icon-info-i-dot {
          stroke: none !important;
          fill: ${(props) => props.$hoverColor} !important;
        }
      }
    }
  }
`;

const InfoTooltip = ({
  content,
  color,
  hoverColor,
  icon = <Icons.Info />,
  placement = "bottom-end",
  additionalProps,
}: {
  content: JSX.Element;
  icon?: ReactNode;
  color: string;
  hoverColor: string;
  placement: Placement;
  additionalProps?: Partial<TippyProps>;
}) => {
  useEffect(() => {
    if (typeof window === "undefined") return;
  }, []);

  return (
    <>
      <StyledTooltip
        content={content}
        interactive={true}
        placement={placement}
        arrow={true}
        {...additionalProps}
      >
        <IconContainer $color={color} $hoverColor={hoverColor}>
          {icon}
        </IconContainer>
      </StyledTooltip>
    </>
  );
};

export default InfoTooltip;
