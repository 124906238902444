import {
  StripeElements,
  StripeError,
  Stripe,
  StripeElementsUpdateOptions,
  ConfirmationToken,
  Appearance,
} from "@stripe/stripe-js";
import Cart from "../../../store/cart/model";

import fetchInternal from "../../../utils/fetch";
import { StripeFormattedAddress } from "../../../utils/types/validateAddressTypes";
import { Color } from "../../../utils/styleDesignSystem";

type BaseAPIPurchase = {
  order_number: string;
  first_name?: string;
  last_name?: string;
  user_id?: string;
  reset_password_token?: string;
  client_secret?: string;
  requires_action?: boolean;
};

type BaseAPIError = {
  error?: any;
  errors?: any;
};

export interface CartAddressPayload {
  shipping_address: StripeFormattedAddress;
  discount_code?: string;
}

export const tryElementsSubmit = async (
  elements: StripeElements,
): Promise<{ success: boolean; error: StripeError | undefined }> => {
  const { error } = await elements.submit();
  const success = !error;

  return { success, error };
};

export const tryCreateConfirmationToken = async (
  stripe: Stripe,
  elements: StripeElements,
) => {
  const { confirmationToken, error } = await stripe.createConfirmationToken({
    elements,
  });

  if (error) {
    console.error("[confirmationTokenError]", error);
  }

  if (confirmationToken) {
    console.log("[confirmationToken]", confirmationToken);
  }

  return { confirmationToken, error };
};
export const tryApiPurchase = async ({
  confirmationToken,
  cartId,
}: {
  confirmationToken: ConfirmationToken;
  cartId: string;
}): Promise<{
  response?: BaseAPIPurchase;
  error?: BaseAPIError;
}> => {
  try {
    const response = await fetchInternal("purchase", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      fetchOptions: {
        addCastleRequestToken: true,
      },
      body: JSON.stringify({
        confirmation_token: confirmationToken.id,
        cart_id: cartId,
      }),
    });

    return { response };
  } catch (error) {
    return { error: error as BaseAPIError };
  }
};

export const tryHandleNextAction = async (
  clientSecret: string,
  stripe: Stripe,
) => {
  const { error, paymentIntent, setupIntent } = await stripe.handleNextAction({
    clientSecret,
  });

  return { paymentIntent, setupIntent, error };
};

export const stripeAppearanceConfig: Appearance = {
  theme: "stripe",
  labels: "floating",
  variables: {
    borderRadius: "4px",
    colorDanger: `${Color.errorRed}`,
    colorPrimary: `${Color.indigoBlue}`,
    colorSuccess: `${Color.successGreen}`,
    colorText: `${Color.indigoBlue}`,
    colorWarning: `${Color.warningOrange}`,
    fontFamily: "CircularXX",
    fontSizeBase: "16px",
    fontVariantLigatures: "normal",
    fontWeightBold: "500",
    fontWeightLight: "450",
    fontWeightMedium: "500",
    fontWeightNormal: "450",
    iconHoverColor: `${Color.indigoBlue40}`,
    iconChevronDownColor: `${Color.indigoBlue}`,
    iconChevronDownHoverColor: `${Color.indigoBlue40}`,
    iconCloseColor: `${Color.indigoBlue}`,
    iconCloseHoverColor: `${Color.indigoBlue40}`,
    iconColor: `${Color.indigoBlue}`,
  },
  rules: {
    ".Input": {
      boxShadow: "none",
    },
    ".AccordionItem": {
      boxShadow: "none",
    },
  },
};

export const stripeElementsConfig = (
  cart: Cart,
  recurringItems: boolean,
): StripeElementsUpdateOptions => {
  return {
    mode: cart.requiresPaymentIntent ? "payment" : "setup",
    amount: cart.total,
    currency: cart.currency,
    ...(recurringItems && { setupFutureUsage: "off_session" }),
    appearance: stripeAppearanceConfig,
  };
};

export const patchCart = async (payload: CartAddressPayload, cart: Cart) => {
  const response = await fetchInternal(`carts/${cart.id}`, {
    method: "PATCH",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    fetchOptions: {
      addCastleRequestToken: true,
    },
    body: JSON.stringify(payload),
  });

  return response;
};

export const setLocalStorage = (purchase: {
  first_name?: string;
  last_name?: string;
  order_number?: string;
  reset_password_token?: string;
}) => {
  const keys = [
    "first_name",
    "last_name",
    "order_number",
    "reset_password_token",
  ] as const;
  keys.forEach((key) => {
    if (purchase[key]) {
      sessionStorage.setItem(`rit-${key}`, purchase[key] as string);
    }
  });
};
