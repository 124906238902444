import React, { useEffect, useState } from "react";
import cartService from "../services/cart";

interface ProductToAdd {
  productOfferId?: string;
  planId?: string;
  quantity: number;
}

interface CartControllerProps {
  productsToAdd: ProductToAdd[];
  activeCart: boolean;
  isProcessing: boolean;
  dispatchAddProductsToCart: (products: ProductToAdd[]) => Promise<void>;
  onClearProducts: () => void;
}

const CartController: React.FC<CartControllerProps> = ({
  productsToAdd,
  activeCart,
  isProcessing,
  dispatchAddProductsToCart,
  onClearProducts,
}) => {
  const [hasDispatched, setHasDispatched] = useState(false);

  useEffect(() => {
    if (
      !hasDispatched &&
      activeCart &&
      !isProcessing &&
      productsToAdd.length > 0
    ) {
      setHasDispatched(true);
      dispatchAddProductsToCart(productsToAdd).finally(() => {
        cartService.openCart();
        onClearProducts();
      });
    }
  }, [
    hasDispatched,
    activeCart,
    isProcessing,
    productsToAdd,
    dispatchAddProductsToCart,
    onClearProducts,
  ]);

  return null;
};

export default CartController;
