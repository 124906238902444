import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "PROMOTION";

export default class Promotion extends Model {
  // Attributes
  name: string = "";
  type: string = "";
  displayName: string = "";
  currency: string = "";
  amountOff: number = 0;
  percentOff: number = 0;
  creditAmount: number = 0;
  activatesAt: string = "";
  expiresAt: string = "";
  priority: number = 0;
  maxDiscount: number = 0;
  slug: string = "";
  appliedType: string = "";

  // Relationships
  productId: string = "";
  storeId: string = "";
  promotionPlanIds: Array<string> = [];

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);

    const relationships = input.relationships;
    this.productId = this.getRelationshipId(relationships, "product");
    this.storeId = this.getRelationshipId(relationships, "store");
    this.promotionPlanIds = this.getRelationshipIds(
      relationships,
      "promotion_plans",
    );
  }
}
