import { createSelector } from "reselect";
import { BillingType } from "../../constants/plan";
import planSelectors from "../plan/selectors";

const _state = (state) => state;
const _byId = (state) => state.cartProducts.byId;
const _allIds = (state) => state.cartProducts.allIds;

const _allIdsForActiveCart = createSelector(
  _byId,
  _allIds,
  (state) => state.carts.meta.activeId,
  (byId, allIds, activeCartId) => {
    return allIds.filter((id) => {
      const cartProduct = byId[id];
      return cartProduct.cartId === activeCartId;
    });
  },
);

const activeCartProducts = createSelector(
  _allIdsForActiveCart,
  _byId,
  (allIdsForActiveCart, byId) => allIdsForActiveCart.map((id) => byId[id]),
);

const sortedActiveCartProducts = createSelector(
  activeCartProducts,
  (activeCartProducts) => {
    const sorted = activeCartProducts.sort((a, b) => {
      const aIsStub = a.id.includes("stub");
      const bIsStub = b.id.includes("stub");
      if (aIsStub && !bIsStub) {
        return 1;
      } else if (!aIsStub && bIsStub) {
        return -1;
      } else {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
    });
    return sorted;
  },
);

const activeCartProductQuantity = createSelector(
  activeCartProducts,
  (activeCartProducts) => {
    let quantity = 0;
    activeCartProducts.forEach((cartProduct) => {
      quantity += cartProduct.fulfillmentQuantity;
    });
    return quantity;
  },
);

const activeCartProductTotal = createSelector(
  activeCartProducts,
  (activeCartProducts) => {
    let total = 0;
    activeCartProducts.forEach((cartProduct) => {
      total += cartProduct.quantity * cartProduct.productPrice;
    });
    return total;
  },
);

const activeCartOneTimeOnly = createSelector(
  _state,
  activeCartProducts,
  (state, activeCartProducts) => {
    const oneTimeProducts = activeCartProducts.filter((cartProduct) => {
      const plan = cartProduct.plan(state);
      return plan && plan.billingType === BillingType.OneTime;
    });

    return (
      activeCartProducts.length > 0 &&
      oneTimeProducts.length === activeCartProducts.length
    );
  },
);

const activeCartContainsEpre = createSelector(
  _state,
  activeCartProducts,
  (state, activeCartProducts) => {
    return activeCartProducts.some((cartProduct) => {
      const plan = cartProduct.plan(state);
      return plan && plan.nickname.includes("EPRE");
    });
  },
);

const activeCartOneTimeAny = createSelector(
  _state,
  activeCartProducts,
  (state, activeCartProducts) => {
    const oneTimeProducts = activeCartProducts.filter((cartProduct) => {
      const plan = cartProduct.plan(state);
      return plan && plan.billingType === BillingType.OneTime;
    });

    return oneTimeProducts.length > 0;
  },
);

const activeNonStubCartProductQuantity = createSelector(
  activeCartProducts,
  (activeCartProducts) => {
    let quantity = 0;
    activeCartProducts.forEach((cartProduct) => {
      if (cartProduct.id.startsWith("stub-")) return;
      quantity += cartProduct.quantity;
    });
    return quantity;
  },
);

const activeCartProductsForPlan = createSelector(
  activeCartProducts,
  (_, props) => props.planId,
  (activeCartProducts, planId) => {
    return activeCartProducts.filter((cartProduct) => {
      return cartProduct.planId === planId;
    });
  },
);

const stubCartProducts = createSelector(_byId, _allIds, (byId, allIds) => {
  return allIds.reduce((products, id) => {
    if (id.startsWith("stub-")) {
      products.push(byId[id]);
    }
    return products;
  }, []);
});

export const activeCartProductForProductOffer = createSelector(
  activeCartProducts,
  (_, productOfferId) => productOfferId,
  (activeCartProducts, productOfferId) => {
    return activeCartProducts.find((cartProduct) => {
      return cartProduct.productOfferId === productOfferId;
    });
  },
);

export const hasProductOfferInCart = createSelector(
  activeCartProducts,
  (activeCartProducts) =>
    activeCartProducts.some((cartProduct) => cartProduct.productOfferId),
);

const activeCartRecurringItems = createSelector(
  _state,
  activeCartProducts,
  (state, activeCartProducts) => {
    return activeCartProducts.filter((cartProduct) => {
      if (cartProduct.productOfferId) {
        return true;
      }
      const plan = planSelectors.planForId(state, {
        id: cartProduct.planId,
      });

      return plan && plan.billingType === BillingType.Recurring;
    });
  },
);

const allSelectors = {
  activeCartProducts,
  sortedActiveCartProducts,
  activeCartProductQuantity,
  activeCartProductTotal,
  activeCartOneTimeOnly,
  activeCartOneTimeAny,
  activeNonStubCartProductQuantity,
  activeCartProductsForPlan,
  activeCartProductForProductOffer,
  stubCartProducts,
  activeCartRecurringItems,
  activeCartContainsEpre,
};
export default allSelectors;
