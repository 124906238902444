import { useSelector } from "react-redux";
import intl from "../../services/intl";
import cartSelectors, {
  showProductOfferLimitBanner,
} from "../../store/cart/selectors";
import { getBundleCartLimit } from "../../utils/bundle";
import CartBanner from "./CartBanner";

export default function CartTopBannerArea({ className }) {
  const activeCart = useSelector(cartSelectors.activeCart);
  const discountAmount =
    activeCart.discountAmount + activeCart.productOfferDiscountAmount;

  const shouldShowLimitBanner = useSelector(cartSelectors.showLimitBanner);

  const shouldShowWipeoutBanner = useSelector(cartSelectors.showWipeoutBanner);

  const quantityLimit = getBundleCartLimit();
  const shouldShowProductOfferLimitBanner = useSelector(
    showProductOfferLimitBanner,
  );

  return (
    <>
      {shouldShowLimitBanner && (
        <CartBanner
          className={`${className} neutral-banner`}
          bannerMessage={intl.t(
            "cart.review-order.banner-limit",
            `You can only have {limit} items in your cart at a time—remove one to add another.`,
            { limit: quantityLimit },
          )}
        />
      )}
      {shouldShowProductOfferLimitBanner && (
        <CartBanner
          className={`${className} neutral-banner`}
          bannerMessage={intl.t(
            "cart.review-order.banner-product-offer-limit",
            "You already have that product bundle in your cart.",
          )}
        />
      )}
      {shouldShowWipeoutBanner && (
        <CartBanner
          className={`${className} neutral-banner`}
          bannerMessage={intl.t(
            "cart.review-order.banner-wipeout",
            `You can only have {limit} items in your cart at a time—we’ve replaced your previous items with the new ones added.`,
            { limit: quantityLimit },
          )}
        />
      )}
      {discountAmount > 0 && (
        <CartBanner
          className={className}
          bannerMessage={intl.t(
            "cart.review-order.banner-discount",
            `You're saving {discountAmount} on your first order`,
            {
              discountAmount: intl.formatCurrency(
                Math.floor(discountAmount / 100).toFixed(0),
                { round: true },
              ),
            },
          )}
        />
      )}
    </>
  );
}
